export enum ErrorType {
  INVALID_URL = 'invalid-url',
  CAMPAIGN_NOT_FOUND = 'invalid-url',
  BILLING_NOT_FOUND = 'invalid-url',
  IP_BLOCKED_BY_SA = 'ip-blocked',
  EMAIL_BLOCKED_BY_SA = 'email-blocked',
  PHONE_BLOCKED_BY_SA = 'phone-blocked',
  LIMIT_EXCEEDED = 'limit-exceeded',
  INVALID_STATUS = 'invalid-request',
  ALREADY_CLAIMED = 'already-claimed',
  INVALID_PASSWORD = 'invalid-password',
  REWARD_NOT_FOUND = 'no-reward',
  CAMPAIGN_IS_NOT_LIVE = 'end',
  CAMPAIGN_IS_PAUSED = 'paused',
  CAMPAIGN_IS_DRAFT = 'draft',
  SERVER_ERROR = 'server-error',
  VALIDATION_ERROR_ACCESSCODE = 'INVALID_OPTIN_CODE'
};

export const PAGE_ERROR: any = [
  'ALREADY_CLAIMED',
  'INVALID_PASSWORD',
  'INVALID_STATUS'
];

export const ERROR_MESSAGE: any = {
  'invalid-url': { message: 'Page Not Found. Please contact your campaign administrator', code: 404 },
  'ip-blocked': { message: 'Your ip has been blocked. Please contact your campaign administrator.', code: 400 },
  'email-blocked': { message: 'Your email has been blocked. Please contact your campaign administrator.', code: 400 },
  'phone-blocked': { message: 'Your phone no has been blocked. Please contact your campaign administrator.', code: 400 },
  'blocked': { message: 'Page Not Found.', code: 400 },
  'invalid-request': { message: "Invalid Request.", code: 400 },
  'limit-exceeded': { message: 'Something went wrong. Please try again after some time.', code: 402 },
  'already-claimed': { message: 'You have already claimed with inputs.', code: 400 },
  'invalid-password': { message: 'Invalid Password. Please re-enter password or contact your campaign administrator.', code: 400 },
  'no-reward': { message: 'Rewards for this campaign are unavailable. For more information, please contact your campaign manager.', code: 404 },
  'no-live': { message: 'Campaign is not live yet. Please contact your campaign administrator.', code: 404 },
  'end': { message: 'Campaign ended.', code: 404 },
  'paused': { message: 'Campaign is currently on pause.', code: 404 },
  'draft': { message: 'Campaign is currently not live.', code: 404 },
  'server-error': { message: 'Something went wrong. Please contact your campaign administrator.', code: 500 },
}

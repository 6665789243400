// ANGULAR DEPENDANCY
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

// EXTERNAL DEPENDANCY
import { Observable, of } from 'rxjs';

// Services
import { CampaignStorageService } from 'src/app/api/services/storage/campaign-storage.service';

@Injectable({ providedIn: 'root', })
export class ResultGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private storage: CampaignStorageService) { }

  /**
   * Checks if the ActivatedComponent can be activated or not based on the token
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   */
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.checkIfAuthorized(route, state);
  }

  /**
    * Check if the ActivatedComponent's can be activated or not based on the token
    * @param {ActivatedRouteSnapshot} route
    * @param {RouterStateSnapshot} state
    */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.checkIfAuthorized(childRoute, state);
  }

  /**
   * Checks if the Activated component is authorized
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   */
  checkIfAuthorized(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    let result: any = this.storage.getResult();
    // validate result
    if (!result) {
      return of(true);
    } else {
      this.router.navigate(['c', route.paramMap.get('cmp_id'), result?.rs_url_code]);
      return of(false);
    }
  }

}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { TransferState, makeStateKey } from '@angular/platform-browser';

// External Modules
import { catchError, EMPTY, map, Observable, of } from "rxjs";
import { SsrCookieService } from "ngx-cookie-service-ssr";

// Services
import { CampaignService } from "src/app/api/services/campaign/campaign.service";
import { CampaignStorageService } from "src/app/api/services/storage/campaign-storage.service";

// Enums
import { ErrorType } from "src/app/constants/enums/campaign/error/error-type.enum";
import { ApiModule } from "src/app/constants/enums/api-module.enum";

// Constants
import { API, API_PREVIEW } from "src/app/constants/api";

const CAMPAIGN_KEY = makeStateKey('campaign');

@Injectable({ providedIn: 'root' })
export class LgCampaignResolver implements Resolve<any> {
  constructor(
    private state: TransferState,
    private campaignService: CampaignService,
    private storage: CampaignStorageService,
    private cookieService: SsrCookieService,
    private router: Router
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> {
    if (this.storage.getResult() && this.storage.getCampaign()) { return of(null); }

    let value: any = this.state.get(CAMPAIGN_KEY, null);

    let previewMode = state.url.indexOf('f/prv') > -1;
    let api = previewMode ? API_PREVIEW : API;

    // Get all query params from route
    const queryParams = route.queryParams;

    // when get query params set in local
    if (queryParams) {
      localStorage.setItem('paramss', JSON.stringify(queryParams));
    }

    let payload = {
      cmp_id: route.paramMap.get('cmp_id'),
      player_cookie: this.cookieService.get(route.paramMap.get('cmp_id')) || route.paramMap.get('url_code'),
      paramss: queryParams || {}
    };

    if (value) {
      this.storage.setCampaignData(value?.data?.campaign, value?.data?.editor,
        value?.data?.validation_setting, value?.data?.advance_setting, value?.data?.result, value?.data?.access, value?.data?.reward);
      if (value?.data?.result) {
        this.router.navigate([`${previewMode ? 'f/prv' : 'f'}`, route.paramMap.get('cmp_id'), value?.data?.result.rs_url_code]);
      }
      return value;
    }

    return this.campaignService.campaignCommon({ module: ApiModule.LEAD_GENERATION, url: api.Campaign }, payload, payload).pipe(map((res: any) => {
      this.state.set(CAMPAIGN_KEY, res);
      this.storage.setCampaignData(res?.data?.campaign, res?.data?.editor,
        res?.data?.validation_setting, res?.data?.advance_setting, res?.data?.result, res?.data?.access, res?.data?.reward);
      if (res?.data?.result) {
        this.router.navigate([`${previewMode ? 'f/prv' : 'f'}`, route.paramMap.get('cmp_id'), res?.data?.result.rs_url_code]);
      }
      return res;
    }), catchError(err => {
      if (err?.error?.data?.campaign) {
        let errorType = ErrorType[err.error.message] || ErrorType.SERVER_ERROR;
        this.storage.setErrorCampaign({ errorType, campaign: err?.error?.data?.campaign });
        return of(true);
      }
      this.router.navigate(['error', ErrorType[err.error.message] || ErrorType.SERVER_ERROR])
      return of(true);
    }));
  }
}
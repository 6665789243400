export const environment = {
    production: true,
    propxyUrl: 'https://master-api.gamifyroute.com/api/v1/Proxy?url=',
    apis: {
      ig_api: 'https://interactive-result-api.gamifyroute.com/api',
      ip_api: 'https://informative-result-api.gamifyroute.com/api',
      lg_api: 'https://form-result-api.gamifyroute.com/api',
    },
    seo_api: 'https://campaign-api.gamifyroute.com/api',
    preview: false,
    bucketAwsUrl: 'https://app-gamifyroute-public-asset.s3.amazonaws.com/',
    defaultImgUrl: './assets/media/inline-svg/placeholder/lazyImageLoad.png',
  };
  
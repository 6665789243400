import { ApiModule } from 'src/app/constants/enums/api-module.enum';
import { environment } from 'src/environments/environment';

export class ApiUtil {

  static getReplaceParams = (path: string, replaceParams: Array<string>, payload: any) => {
    replaceParams.forEach(key => {
      key = key.replace('{{', '').replace('}}', '');
      path = path.split(`{{${key}}}`)
        .join(payload[key] || '');
    });
    return path;
  }

  static getApiUrl(config, payload) {
    const keywords = config.url.match(/{{(.*?)\}}/g);
    let url = config.url;
    if (keywords?.length) {
      url = this.getReplaceParams(url, keywords, payload);
    }

    let apiUrl = '';
    switch (config.module) {
      case ApiModule.INTERACTIVE_GAME:
        apiUrl = environment.apis.ig_api;
        break;
      case ApiModule.INFORMATIVE_PAGE:
        apiUrl = environment.apis.ip_api;
        break;
      case ApiModule.LEAD_GENERATION:
        apiUrl = environment.apis.lg_api;
        break;
      case ApiModule.CAMPAIGN:
        apiUrl = environment.seo_api;
        break;
      default:
        apiUrl = environment.apis.ig_api;
    }
    return `${apiUrl}${url}`;
  }
}
import { Injectable } from '@angular/core';
import moment from 'moment-timezone';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CampaignStorageService {

  errorCampaign: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  campaign: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  game: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  result: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  reward: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  advance: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  validation: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  access: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor() { }

  setTimeZone(tz) {
    tz && moment.tz.setDefault(tz);
  }

  public setCampaignData(campaign, game, validation, advance, result, access, reward): any {
    this.campaign.next(campaign);
    this.access.next(access);
    this.setTimeZone(campaign?.cmp_tz);
    this.game.next(game);
    this.validation.next(validation);
    this.advance.next(advance);
    this.setReward(reward);
    this.reward.next(reward);
    result && this.setResult(result);
    access && this.setAccess(access);
  }

  public setIpCampaignData(campaign, game, result, access): any {
    campaign && this.campaign.next(campaign);
    game && this.game.next(game);
    this.setTimeZone(campaign?.cmp_tz);
    result && this.setResult(result);
    access && this.setAccess(access);
  }

  public setCampaign(campaign?: any): any {
    this.setTimeZone(campaign?.cmp_tz);
    this.campaign.next(campaign);
  }

  public getCampaign(observable: boolean = false): Observable<any> {
    if (!observable) return this.campaign.getValue();
    return this.campaign.asObservable();
  }

  public getErrorCampaign(observable: boolean = false): Observable<any> {
    if (!observable) return this.errorCampaign.getValue();
    return this.errorCampaign.asObservable();
  }

  public setErrorCampaign(campaign?: any): any {
    this.errorCampaign.next(campaign);
  }

  public setGame(game?: any): any {
    this.game.next(game);
  }

  public getGame(observable: boolean = false): Observable<any> {
    if (!observable) return this.game.getValue();
    return this.game.asObservable();
  }

  public setReward(result?: any): any {
    this.reward.next(result);
  }

  public getReward(observable: boolean = false): Observable<any> {
    if (!observable) return this.reward.getValue();
    return this.reward.asObservable();
  }

  public setResult(result?: any): any {
    this.result.next(result);
  }

  public getResult(observable: boolean = false): Observable<any> {
    if (!observable) return this.result.getValue();
    return this.result.asObservable();
  }

  public setAccess(access?: any): any {
    this.access.next(access);
  }

  public getAccess(observable: boolean = false): Observable<any> {
    if (!observable) return this.access.getValue();
    return this.access.asObservable();
  }

  public setValidation(validation?: any): any {
    this.validation.next(validation);
  }

  public getValidation(observable: boolean = false): Observable<any> {
    if (!observable) return this.validation.getValue();
    return this.validation.asObservable();
  }

  public setAdvance(advance?: any): any {
    this.advance.next(advance);
  }

  public getAdvance(observable: boolean = false): Observable<any> {
    if (!observable) return this.advance.getValue();
    return this.advance.asObservable();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResultGuard } from './_core/guards/result.guard';

//component
import { IgEditorComponent } from './modules/editor/interactive-game/ig-editor/ig-editor.component';

// resolver
import { IgCampaignResolver } from './_core/resolve/ig-campaign.resolver';
import { IgResultResolver } from './_core/resolve/ig-result.resolver';
import { IpCampaignResolver } from './_core/resolve/ip-campaign.resolver';
import { LgCampaignResolver } from './_core/resolve/lg-campaign.resolver';
import { IpResultResolver } from './_core/resolve/ip-result.resolver';
import { LgResultResolver } from './_core/resolve/lg-result.resolver';

const routes: Routes = [
  {
    path: 'c/:cmp_id',
    // component: IgEditorComponent,
    loadChildren: () => import('./modules/editor/interactive-game/ig-editor/ig-editor.module').then((m) => m.IgEditorModule),
    canActivate: [ResultGuard],
    canActivateChild: [ResultGuard],
    resolve: { data: IgCampaignResolver },
  },
  {
    path: 'c/prv/:cmp_id',
    // component: IgEditorComponent,
    loadChildren: () => import('./modules/editor/interactive-game/ig-editor/ig-editor.module').then((m) => m.IgEditorModule),
    canActivate: [ResultGuard],
    canActivateChild: [ResultGuard],
    resolve: { data: IgCampaignResolver },
  },
  {
    path: 'l/:cmp_id',
    // component: IgEditorComponent,
    loadChildren: () => import('./modules/editor/informative-page/ip-editor/ip-editor.module').then((m) => m.IpEditorModule),
    canActivate: [ResultGuard],
    canActivateChild: [ResultGuard],
    resolve: { data: IpCampaignResolver },
  },
  {
    path: 'l/prv/:cmp_id',
    // component: IgEditorComponent,
    loadChildren: () => import('./modules/editor/informative-page/ip-editor/ip-editor.module').then((m) => m.IpEditorModule),
    canActivate: [ResultGuard],
    canActivateChild: [ResultGuard],
    resolve: { data: IpCampaignResolver },
  },
  {
    path: 'f/:cmp_id',
    // component: IgEditorComponent,
    loadChildren: () => import('./modules/editor/lead-generation/lg-editor/lg-editor.module').then((m) => m.LgEditorModule),
    canActivate: [ResultGuard],
    canActivateChild: [ResultGuard],
    resolve: { data: LgCampaignResolver },
  },
  {
    path: 'f/prv/:cmp_id',
    // component: IgEditorComponent,
    loadChildren: () => import('./modules/editor/lead-generation/lg-editor/lg-editor.module').then((m) => m.LgEditorModule),
    canActivate: [ResultGuard],
    canActivateChild: [ResultGuard],
    resolve: { data: LgCampaignResolver },
  },
  {
    path: 'c/:cmp_id/:url_code',
    // component: IgEditorComponent,
    loadChildren: () => import('./modules/editor/interactive-game/ig-editor/ig-editor.module').then((m) => m.IgEditorModule),
    resolve: { data: IgResultResolver },
  },
  {
    path: 'c/prv/:cmp_id/:url_code',
    // component: IgEditorComponent,
    loadChildren: () => import('./modules/editor/interactive-game/ig-editor/ig-editor.module').then((m) => m.IgEditorModule),
    resolve: { data: IgResultResolver },
  },
  {
    path: 'l/:cmp_id/:url_code',
    // component: IgEditorComponent,
    loadChildren: () => import('./modules/editor/informative-page/ip-editor/ip-editor.module').then((m) => m.IpEditorModule),
    resolve: { data: IpResultResolver },
  },
  {
    path: 'l/prv/:cmp_id/:url_code',
    // component: IgEditorComponent,
    loadChildren: () => import('./modules/editor/informative-page/ip-editor/ip-editor.module').then((m) => m.IpEditorModule),
    resolve: { data: IpResultResolver },
  },
  {
    path: 'f/:cmp_id/:url_code',
    // component: IgEditorComponent,
    loadChildren: () => import('./modules/editor/lead-generation/lg-editor/lg-editor.module').then((m) => m.LgEditorModule),
    resolve: { data: LgResultResolver },
  },
  {
    path: 'f/prv/:cmp_id/:url_code',
    // component: IgEditorComponent,
    loadChildren: () => import('./modules/editor/lead-generation/lg-editor/lg-editor.module').then((m) => m.LgEditorModule),
    resolve: { data: LgResultResolver },
  },
  {
    path: 'error/:code',
    loadChildren: () => import('./modules/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: '', pathMatch: 'full', redirectTo: 'error'
  },
  {
    path: '**',
    redirectTo: 'error',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

// External Modules
import { Observable } from 'rxjs';

// Constants
import { ApiUtil } from 'src/app/_core/utils/api/api.util';

@Injectable({ providedIn: 'root' })
export class CampaignService {

  constructor(protected http: HttpClient) { }

  campaignCommon(config: any, basic, data = null, type = null): Observable<any> {
    const url = ApiUtil.getApiUrl(config, basic);
    
    const headers = new HttpHeaders();
    if (!headers['vary']) {
      headers['vary'] = [
        { key: 'Vary', value: 'Access-Control-Request-Headers' },
        { key: 'Vary', value: 'Access-Control-Request-Method' },
        { key: 'Vary', value: 'Origin' },
      ];
    }

    if (type === 'get')
      return this.http.get(url);
    else if (type === 'put')
      return this.http.put(url, data);
    return this.http.post(url, data);
  }
}

export const API = {
  Campaign: '/v1/result/{{cmp_id}}',
  CampaignResult: '/v1/result/{{cmp_id}}/{{url_code}}',
  Optin: '/v1/result/optin/{{cmp_id}}',
  Play: '/v1/result/play/{{cmp_id}}/{{url_code}}',
  Result: '/v1/result/result/{{cmp_id}}/{{url_code}}',
  ClaimPending: '/v1/result/claim-pending/{{cmp_id}}/{{url_code}}',
  ClaimSubmitted: '/v1/result/claim-submit/{{cmp_id}}/{{url_code}}',
  ValidationPending: '/v1/result/validation-pending/{{cmp_id}}/{{url_code}}',
  ValidationSubmitted: '/v1/result/validation-submit/{{cmp_id}}/{{url_code}}',
  Expired: '/v1/result/expired/{{cmp_id}}/{{url_code}}',
};

export const API_PREVIEW = {
  Campaign: '/v1/preview/{{cmp_id}}',
  CampaignResult: '/v1/preview/{{cmp_id}}/{{url_code}}',
  Optin: '/v1/preview/optin/{{cmp_id}}',
  Play: '/v1/preview/play/{{cmp_id}}/{{url_code}}',
  Result: '/v1/preview/result/{{cmp_id}}/{{url_code}}',
  ClaimPending: '/v1/preview/claim-pending/{{cmp_id}}/{{url_code}}',
  ClaimSubmitted: '/v1/preview/claim-submit/{{cmp_id}}/{{url_code}}',
  ValidationPending: '/v1/preview/validation-pending/{{cmp_id}}/{{url_code}}',
  ValidationSubmitted: '/v1/preview/validation-submit/{{cmp_id}}/{{url_code}}',
  Expired: '/v1/preview/expired/{{cmp_id}}/{{url_code}}',
};
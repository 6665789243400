import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";

// External Modules
import { catchError, EMPTY, map, Observable, of } from "rxjs";
import { SsrCookieService } from "ngx-cookie-service-ssr";

// Services
import { CampaignStorageService } from "src/app/api/services/storage/campaign-storage.service";
import { CampaignService } from "src/app/api/services/campaign/campaign.service";

// Constants
import { API, API_PREVIEW } from "src/app/constants/api";
import { ErrorType } from "src/app/constants/enums/campaign/error/error-type.enum";
import { ApiModule } from "src/app/constants/enums/api-module.enum";


@Injectable({ providedIn: 'root' })
export class IpResultResolver implements Resolve<any> {
  constructor(
    private campaignService: CampaignService,
    private storage: CampaignStorageService,
    private router: Router,
    private cookieService: SsrCookieService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> {
    if (this.storage.getResult() && this.storage.getCampaign()) { return of(null); }

    let previewMode = state.url.indexOf('l/prv') > -1;

    let api = previewMode ? API_PREVIEW : API;
    let payload = {
      cmp_id: route.paramMap.get('cmp_id'),
      url_code: route.paramMap.get('url_code')
    };

    return this.campaignService.campaignCommon({ module: ApiModule.INFORMATIVE_PAGE, url: api.CampaignResult }, payload, payload).pipe(map((res: any) => {
      // set cookie
      this.cookieService.set(payload.cmp_id, res?.data?.result?.rs_cookie_no, { path: '/' });
      this.storage.setCampaignData(res?.data?.campaign, res?.data?.editor, res?.data?.validation_setting, res?.data?.advance_setting,
        res?.data?.result, res?.data?.access, res?.data?.reward);
      return res;
    }), catchError(err => {
      // navigate to error page
      if (err?.error?.data?.campaign) {
        let errorType = ErrorType[err.error.message] || ErrorType.SERVER_ERROR;
        this.storage.setErrorCampaign({ errorType, campaign: err?.error?.data?.campaign });
        return of(true);
      }
      this.router.navigate(['error', ErrorType[err.error.message] || ErrorType.SERVER_ERROR])
      return of(true);
    }));
  }
}

/**
 * Http Api Method config enum for api call
 */

export enum ApiModule {
  INTERACTIVE_GAME = 'interactiveGame',
  INFORMATIVE_PAGE = 'informativePage',
  LEAD_GENERATION = 'leadGeneration',
  CAMPAIGN = 'campaign',
}